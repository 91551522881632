import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/menu',
      name: 'menu',
      component: () => import('./views/VMenu.vue')
    },
    {
      path: '/powerReserve',
      name: 'powerReserve',
      component: () => import('./views/VPowerReserve.vue')
    },
    {
      path: '/verification',
      name: 'verification',
      component: () => import('./views/Verification.vue')
    },
    {
      path: '/development',
      name: 'development',
      component: () => import('./views/Development.vue')
    },
    {
      path: '/charging',
      name: 'charging',
      component: () => import('./views/Charging.vue')
    },
    {
      path: '/where-charge',
      name: 'where-charge',
      component: () => import('./views/ChargingCar.vue')
    },
    {
      path: '/how-long-to-charge',
      name: 'how-long-to-charge',
      component: () => import('./views/VHowLongToCharge.vue')
    },
    {
      path: '/navigator',
      name: 'navigator',
      component: () => import('./views/Navigator.vue')
    },
    {
      path: '/*',
      component: () => import('./views/VMenu.vue'),
    }
  ]
});
export {router};
