import { axiosInstance } from "./axiosInstance";


export const apiRequest = async (url, method, body = {}, options = {}) => {

  if (method === 'get' || method === 'delete') {
    return axiosInstance[method](`${url}`, {...options });
  }

  return axiosInstance[method](`${url}`, body, { ...options });
};