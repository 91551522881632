import Vue from "vue";
import Vuex from "vuex";
import test from './modules/test';
import copyObject from "./../functions/copyObject";
Vue.use(Vuex);

// копии изначальных состояний всех модулей,
// это нужно для сброса в изначальное положение
// module: copyObject(module.state)
let initialState = {
  test: copyObject(test.state),
};

export const store = new Vuex.Store({
  modules: {
    test,
  },
  mutations: {
    // сброс состояний всех модулей
    reset(state) {
      Object.keys(initialState).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
});
