import axios from 'axios'

export const axiosInstance = axios.create({
  baseURL: getCurrentDomain(),
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

function getCurrentDomain() {
  // если локально => domain http://localhost:8080
  let domain = window.location.origin;

  // если это маркап
  /* if (domain === "http://markup.nutnetdev.ru" || process.env.NODE_ENV === 'development') {
    return process.env.VUE_APP_HOST
  } */

  return domain + '/api';
}
