import {api} from "./../../api/api";

export default {
    state: {
        test: 'Тест'
    },
    getters: {
        test: state => state.test,
    },
    mutations: {
        testMutat(state, payload) {
            state.test = payload
        },
    },
    actions: {
        testAct({commit}) {
            api.testiframe()
                .then(response => {
                    commit('testMutat', response.data)
                })
                .catch(e => {
                    console.error('API ERROR => test', e);
                })
        },
        testqwe({commit}, {postId}) {
            api.testqwe(postId)
                .then(response => {
                    commit('testMutat', response.data)
                })
                .catch(e => {
                    console.error('API ERROR => test', e);
                })
        }
    },
}